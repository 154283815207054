:root {
  --title-letter-spacing: auto;

  --title-24-line-height: 45px;
  --title-30-line-height: 45px;
  --title-32-line-height: 45px;
  --title-44-line-height: 45px;

  --title-scale-up: 0.2rem;
  --title-scale-down-1: 0.2rem;
  --title-scale-down-2: 0.4rem;
  --title-scale-down-3: 0.6rem;
  --title-scale-down-4: 0.8rem;

  --title-font-24: calc(var(--base-title-size) * 2.4);
  --title-font-30: calc(var(--base-title-size) * 3);
  --title-font-32: calc(var(--base-title-size) * 3.2);
  --title-font-44: calc(var(--base-title-size) * 4.4);
}

.title-24,
.title-30, 
.title-32, /* common */
.title-44 {
  font-weight: var(--font-weight-500);
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}

/* ---------- title 24 ---------- */
.title-24 {
  font-size: 24px;
}
@media (min-width: 1600px) {
  .title-24 {
    font-size: calc(var(--title-font-24) + 0.1rem);
  }
}
@media (max-width: 1200px) {
  .title-24 {
    font-size: calc(var(--title-font-24) - 0.1rem);
  }
}
@media (max-width: 768px) {
  .title-24 {
    font-size: calc(var(--title-font-24) - 0.1rem);
  }
}
@media (max-width: 460px) {
  .title-24 {
    font-size: calc(var(--title-font-24) - 0.1rem);
  }
}
@media (max-width: 380px) {
  .title-24 {
    font-size: calc(var(--title-font-24) - 0.1rem);
  }
}

/* ---------- title 30 ---------- */
.title-30 {
  font-size: 32px;
}
@media (min-width: 1600px) {
  .title-30 {
    font-size: calc(var(--title-font-30) + var(--title-scale-up));
  }
}
@media (max-width: 1200px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-1));
  }
}
@media (max-width: 768px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-4));
  }
}

/* ---------- title 32 ---------- */
.title-32 {
  font-size: 32px;
}
@media (min-width: 1600px) {
  .title-32 {
    font-size: calc(var(--title-font-32) + var(--title-scale-up));
  }
}
@media (max-width: 1200px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-1));
  }
}
@media (max-width: 768px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-4));
  }
}

/* ---------- title 44 ---------- */
.title-44 {
  font-size: 32px;
}
@media (min-width: 1600px) {
  .title-44 {
    font-size: calc(var(--title-font-44) + var(--title-scale-up));
  }
}
@media (max-width: 1200px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-1));
  }
}
@media (max-width: 768px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-4));
  }
}
