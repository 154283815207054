.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  flex-direction: column;
}
.text1 {
  color: black;
  font-size: 60px;
}
.text2 {
  color: #000000;
}
.button {
  color: white;
  background-color: pink;
  border-radius: 50px;
  height: fit-content;
  width: fit-content;
  padding: 6px 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  background-color: #000000;
}
.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 150%;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  transition: opacity 0.1s;
}
.button:hover::before {
  opacity: 1;
  animation: shine 1s forwards;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(60deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(60deg);
  }
}
