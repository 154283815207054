.headerWrapper {
  background-color: var(--header-bg-color);
  box-shadow: var(--box-shadow-color);
  position: fixed;
  z-index: 150;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ---------- left info ---------- */
.leftInfo {
  display: grid;
  align-items: center;
  width: auto;
  height: 100%;
}
.leftInfo img {
  width: 150px;
}

/* ---------- right info ---------- */
.rightInfo {
  color: black;
}
.leftInfo a {
  display: flex;
  align-items: center;
  gap: 5px;
}
.rightInfo :global(.hamburger-react div) {
  height: 2px !important;
}

/* ---------- dropdown ---------- */
.dropdownContainer {
  background-color: var(--white-color);
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  width: 100%;
  transform: translateX(100%);
  overflow: hidden;
  z-index: 150;
  transition: all 0.2s ease-in;
}
.showDropdown {
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}
