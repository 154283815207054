html {
  font-size: 10px;

  --base-text-size: 1rem;
  --base-title-size: 1rem;
}

:root {
  /* ---------- container width ---------- */
  --width-web: 90%;
  --max-width-web: 1400px;

  --width-mobile: calc(100% - 30px);

  /* ---------- theme colors ---------- */
  --primary-color: #da1313;
  --primary-light-color: #da1313;
  --primary-tint-color: #da1313;
  --primary-dark-color: #da1313;

  --secondary-color: #1c2792;
  --secondary-light-color: #9d3704;
  --secondary-tint-color: #000000;
  --secondary-dark-color: #011a41;

  /* ---------- static colors ---------- */
  --white-color: #ffffff;
  --white-light-color: #dddddd;
  --white-tint-color: #bbbbbb;
  --white-dark-color: #aaaaaa;

  --black-color: #000000;
  --black-light-color: #666666;
  --black-tint-color: #444444;
  --black-dark-color: #222222;

  --gray-bgcolor: #f1f1f1;
  --gray-light-bgcolor: #f9f9f9;
  --gray-tint-bgcolor: #f4f4f4;

  --box-shadow-color: 1px 1px 14px 1px #0000000d;

  /* ---------- font weight ---------- */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
}
