.footerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all 0.5s;
  padding-bottom: 15px;
  /* border-top: 1px solid #313131; */
  position: relative;
  /* background: radial-gradient(circle at 50% 50%, #0e040d 0%, #0b041b 100%); */
  background: linear-gradient(to bottom, #1d2a96 0%, #1c2792 100%);
}
.footerContainer {
  width: 70%;
  padding: 50px 0 0px 0;
}
.headerWrapper {
  background-color: #ebf7ff;
}
.headerConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding-top: 50px;
}
.headerConatiner .btnContainer {
  border-radius: 8px;
  padding: 4px 30px;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-dark-color);
  width: fit-content;
  cursor: pointer;
}
.mapContainer {
  position: relative;
  width: 100%;
}
.mapContainer img {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* ---------- top logo ---------- */
.patternBg {
  width: 100%;
}
.patternBg.mobile {
  display: none;
}
.leftPatternAsset {
  position: absolute;
  bottom: 50px;
  left: 0;
  opacity: 0;
}

/* --------- logo section ----------  */
.logoLinkcontainer {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.logoContainerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  gap: 17px;
}
.logoContainerInfo .logo {
  width: 220px;
}
.logoContainerInfo .companyName {
  color: var(--white-color);
  font-weight: 200;
  margin-top: 10px;
  margin-bottom: 9px;
  width: 80%;
}
.logoContainerInfo .slogan {
  color: #cccccc;
  margin-bottom: 20px;
  font-weight: 200;
  letter-spacing: 2px;
}

/* --------- social media ----------  */

.mediaIconContainer {
  display: flex;
  gap: 15px;
}
.mediaIconContainer a {
  padding: 10px;
  color: white;
  border-radius: 10px;
  display: grid;
  place-items: center;
  transition: all 2s;
  background-color: #ffffff63;
  position: relative;
  overflow: hidden;
}

.mediaIconContainer a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity 0.1s;
}

.mediaIconContainer a:hover::before {
  opacity: 1;
  animation: shine 0.7s forwards;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(45deg);
  }
}

.mediaIconContainer a svg {
  font-size: 25px;
}

/* ---------- menu links ---------- */
.menuLinksContainer {
  display: flex;
  flex-direction: column;
}
.menuLinksContainer .title {
  color: var(--white-color);
  margin-bottom: 29px;
  font-weight: 400;
  width: max-content;
}
.menuLinksContainer .linkInfo {
  color: var(--white-light-color);
  margin-bottom: 14px;
  text-decoration: none;
  display: block;
  font-weight: 300;
  max-width: 208px;
  cursor: pointer;
}
.menuLinksContainer .linkInfo:hover {
  color: white;
}
.linkInfoContainer {
  scale: 1;
  transition: scale 0.5s;
  transition-duration: 0.3s;
}
.linkInfoContainer:hover {
  transition-duration: 0.3s;
  scale: 1.05;
}

/* --------- copy rights ---------- */
.copyRightsContainer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ffffff61;
  margin-top: 30px;
  margin-bottom: 35px;
  padding-top: 21px;
  font-family: var(--sui-font-family-s600);
  letter-spacing: var(--sui-letter-spacing);
  font-weight: 300;
}
.copyRightsContainer .leftInfo {
  color: var(--white-color);
  letter-spacing: 0.5px;
}
.rightInfo {
  display: flex;
  gap: 20px;
}
.copyRightsContainer .rightInfo {
  color: var(--white-color);
  display: block;
  letter-spacing: 0.5px;
}

.copyRightsContainer .rightInfo a {
  text-decoration: none;
  color: var(--white-color);
  /* font-weight: 200; */
  transition: all 0.5s;
}
.serviceProvider {
  color: #48b5ff !important;
}
.copyRightsContainer .rightInfo a:hover {
  color: white !important;
  scale: 1.1;
}

/* ---------------------media quries--------------------
  ----------------------------------------------------- */
@media (max-width: 1340px) {
  .mailEnquiryContainer {
    width: 90%;
  }
  .footerContainer {
    width: 90%;
  }
}

@media (max-width: 1024px) {
  .logoLinkcontainer {
    flex-direction: column;
  }
  .mailEnquiryContainer {
    flex-direction: column;
  }
  .mailEnquiryContainer .rightInfo {
    display: grid;
    width: max-content;
    gap: 0px;
  }
  .mailEnquiryContainer .rightInfo .textInfo {
    margin-bottom: 0;
  }
  .mailEnquiryContainer .leftInfo .textInfo1 {
    margin-bottom: 5px;
  }
  .mailEnquiryContainer .leftInfo .textInfo2 {
  }
  .mailEnquiryContainer .leftInfo {
    margin-bottom: 35px;
  }
}

@media (max-width: 768px) {
  .menuLinksContainer {
    align-items: center;
    justify-content: center;
  }
  .patternBg.mobile {
    display: block;
  }
  .patternBg.web {
    display: none;
  }
  .mailEnquiryContainer form :global(.ant-input) {
  }
  .logoContainerInfo .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .logoContainerInfo .companyName {
  }
  .copyRightsContainer {
    flex-direction: column;
  }
  .copyRightsContainer .rightInfo,
  .copyRightsContainer .leftInfo {
    display: block;
    text-align: center;
    width: 100%;
  }
  .copyRightsContainer .rightInfo {
    margin-top: 10px;
    display: grid;
    align-items: center;
    gap: 5px;
  }
  .copyRightsContainer .rightInfo span {
    color: var(--sui-color-white-600);
  }
  .copyRightsContainer .rightInfo a {
  }
  .logoContainerInfo {
    font-family: var(--sui-font-family-s600);
    letter-spacing: var(--sui-letter-spacing);
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .menuLinksContainer {
    justify-content: center;
  }
  .menuLinksContainer .title {
    text-align: center;
    margin-bottom: 17px;
  }
  .menuLinksContainer .linkInfo {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) {
  .copyRightsContainer {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .mailEnquiryContainer .rightInfo .textInfo {
  }
  .mailEnquiryContainer .leftInfo .textInfo1 {
    margin-bottom: 5px;
  }
  .mailEnquiryContainer .leftInfo .textInfo2 {
  }
}
