.hamburgerMenu .hamburger-react {
  width: 40px !important;
}

.closeIcon.hamburgerMenu .hamburger-react div:nth-of-type(1) {
  top: 10px !important;
}
.openIcon.hamburgerMenu .hamburger-react div {
  height: 2px !important;
  border-radius: 25px;
}
.openIcon.hamburgerMenu .hamburger-react div:nth-of-type(1) {
  width: 35px !important;
  left: 5px !important;
  top: 15px !important;
}
.openIcon.hamburgerMenu .hamburger-react div:nth-of-type(2) {
  width: 27px !important;
  top: 25px !important;
  left: 13px !important;
  height: 1.8px !important;
}
.openIcon.hamburgerMenu .hamburger-react div:nth-of-type(3) {
  width: 20px !important;
  top: 35px !important;
  left: 20px !important;
}
