:root {
  --text-letter-spacing: auto;

  --text-14-line-height: 25px;
  --text-16-line-height: 30px;
  --text-18-line-height: 30px;
  --text-20-line-height: 30px;
  --text-22-line-height: 30px;
  --text-24-line-height: 30px;

  --text-scale-up: calc(var(--base-text-size) * 0.1);
  --text-scale-down: calc(var(--base-text-size) * 0.1);

  --text-font-14: calc(var(--base-text-size) * 1.4);
  --text-font-16: calc(var(--base-text-size) * 1.6);
  --text-font-18: calc(var(--base-text-size) * 1.8);
  --text-font-20: calc(var(--base-text-size) * 2);
  --text-font-22: calc(var(--base-text-size) * 2.2);
  --text-font-24: calc(var(--base-text-size) * 2.4);
}

.text-14,
.text-16, /* common */
.text-18,
.text-20,
.text-22,
.text-24 {
  font-weight: var(--font-weight-300);
  font-family: var(--text-font-family);
  line-height: var(--text-line-height);
  letter-spacing: var(--text-letter-spacing);
}

/* ---------- text 14 ---------- */
.text-14 {
  font-size: var(--text-font-14);
  line-height: var(--text-14-line-height);
}
@media (min-width: 1600px) {
  .text-14 {
    font-size: calc(var(--text-font-14) + var(--text-scale-up));
  }
}
@media (max-width: 380px) {
  .text-14 {
    font-size: calc(var(--text-font-14) - var(--text-scale-down));
  }
}

/* ---------- text 16 ---------- */
.text-16 {
  font-size: var(--text-font-16);
  line-height: var(--text-16-line-height);
}
@media (min-width: 1600px) {
  .text-16 {
    font-size: calc(var(--text-font-16) + var(--text-scale-up));
  }
}
@media (max-width: 380px) {
  .text-16 {
    font-size: calc(var(--text-font-16) - var(--text-scale-down));
  }
}

/* ---------- text 18 ---------- */
.text-18 {
  font-size: var(--text-font-18);
  line-height: var(--text-18-line-height);
}
@media (min-width: 1600px) {
  .text-18 {
    font-size: calc(var(--text-font-18) + var(--text-scale-up));
  }
}
@media (max-width: 380px) {
  .text-18 {
    font-size: calc(var(--text-font-18) - var(--text-scale-down));
  }
}

/* ---------- text 20 ---------- */
.text-20 {
  font-size: var(--text-font-20);
  line-height: var(--text-20-line-height);
}
@media (min-width: 1600px) {
  .text-20 {
    font-size: calc(var(--text-font-20) + var(--text-scale-up));
  }
}
@media (max-width: 380px) {
  .text-20 {
    font-size: calc(var(--text-font-20) - var(--text-scale-down));
  }
}
