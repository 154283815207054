/* Dropdown Menu */

.dropdownWrapper {
  width: 100%;
  z-index: 200;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.dropdownContainer {
  width: 92%;
  z-index: 200;
}
.dropdownContainer .menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #313131;
  background: transparent;
  padding: 16px 0 15px 33px;
  transition: background 0.5s;
  height: fit-content;
  padding-right: 5px;
  cursor: pointer;
  /* border-bottom: 1px solid #eeeeee; */
  box-shadow: 4px 3px 6px 1px #00000012;
  border-radius: 37px;
  margin-bottom: 25px;
  font-weight: 400;
}
.dropdownContainer .activeMenuItem {
  color: var(--primary-color);
}
.arrowIcon {
  color: black;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.dropdownHeader {
  display: flex;
  justify-content: center;
  width: 100%;
  color: black;
  position: relative;
}
.backArrowIcon {
  position: absolute;
  left: -10px;
  color: black;
  font-size: 24px;
  display: flex;
  align-items: center;
}
.lastMenuItemWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.lastMenuItem {
  margin-top: 30px;
  border-radius: 64px;
  display: flex;
  gap: 30px;
  justify-content: center;
  color: var(--white-color);
  background-color: var(--primary-color);
  font-weight: 400;
  letter-spacing: 2.8px;
  padding: 5px 30px;
  cursor: pointer;
}
.lastMenuItem .mediaMenu {
  cursor: pointer;
}
